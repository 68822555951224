<script lang="ts" setup>

</script>

<template>
  <svg width="340" height="354" viewBox="0 0 340 354" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="var(--esc-vector-opacity, 0.5)">
      <path
        d="M334.399 191.055C356.854 0.0726634 314.516 -34.1592 136.323 31.3091C127.587 35.1409 119.048 38.9151 111.102 42.5164C-63.2435 109.426 -4.92766 198.405 96.321 282.548C250.988 397.048 301.445 377.621 334.399 191.055Z"
        fill="var(--esc-vector-fill-color, #FFF)"
      />
    </g>
  </svg>
</template>

<style scoped>
svg {
  mix-blend-mode: var(--esc-vector-mix-blend-mode, soft-light);
}
</style>
